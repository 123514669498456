<template>
    <div id="sidebar-wrapper">
        <ul class="sidebar-nav">
            <li class="sidebar-brand zap-bg-rosa" style="text-indent: unset;">
                <router-link to='/'>
                  <img src="/assets/images/ezap-neg.png" class="img-fluid">
                </router-link>
            </li>
            <li :class="{ 'active': $route.name === 'Home' }">
                <router-link to="/"><i class="fa fa-home"></i> Home</router-link>
            </li>
            <li v-if="isAdmin" :class="{ 'active': $route.meta.section === 'users' }">
                <router-link :to="{name: 'AdminUsers'}"><i class="fa fa-users-cog"></i> Utilizadores</router-link>
            </li>
            <li v-if="isAdmin" :class="{ 'active': $route.meta.section === 'orgs' }">
                <router-link :to="{name: 'OrganizationList'}"><i class="fa fa-sitemap"></i> Organizações</router-link>
            </li>
            <li v-if="isAdmin" :class="{ 'active': $route.meta.section === 'products' }">
                <router-link :to="{name: 'ProductList'}"><i class="fa fa-product-hunt"></i> Produtos</router-link>
            </li>
        </ul>
    </div>
</template>
<script>

    // import {getLanguageOptions} from "../../language-utils";
    // import {i18n} from "../../main"
    import {mapGetters} from "vuex";

    export default {
        components: {
        },
        data () {
            return {
                // selectedMenu: '',
                // selected: i18n.locale,
                // languageOptions: getLanguageOptions(),

                // showAgencyMenu: false
            }
        },
        watch: {
        },
        mounted () {
            // ambas estas vars devem estar no main.scala.html
        },
        beforeDestroy() {
        },
        computed: {
            ...mapGetters({
                profile: 'profile',
                isAdmin: 'isAdmin'
            }),
        },
        methods: {
        }
    }
</script>

<template>
    <div class="row">
        <div class="col-sm-10 mx-auto">

            <div class="card">
                <div class="card-header">
                    <img class="img-fluid" src='/assets/images/ezap-pos.png'/>
                    <h3>{{$t("root.invited_as_user")}}</h3>
                </div>
                <div class="card-body">
                    <form v-on:submit.prevent="createAccount" id="joinForm" novalidate>
                        <!--<div class="form-group">
                            <label for="organizationName" class="control-label">{{$t("root.organization")}}:</label>
                            <p class="form-control-static" id="organizationName" >{{orgName}}</p>
                        </div>-->
                        <div class="form-group">
                            <label>{{$t("root.email")}}:</label>
                            <p class="form-control-static">{{inviteData.email}}</p>
                        </div>

                        <div class="form-group" :class="{ 'has-error': $v.inviteData.firstName.$error }">
                            <label for="firstName" class="control-label">{{$t("root.firstname")}} *</label>
                            <input class="form-control" type="text" name="firstName" id="firstName" placeholder=""
                                   :class="{'is-invalid' : $v.inviteData.firstName.$error}"
                                   v-model.trim="$v.inviteData.firstName.$model"/>
                            <span class="invalid-feedback" v-show="$v.inviteData.firstName.$error && !$v.inviteData.firstName.required">
                                {{$t("root.required")}}</span>
                            <span class="invalid-feedback" v-show="$v.inviteData.firstName.$error && !$v.inviteData.firstName.maxLength">
                                {{$t("root.generic_max_size", {max: $v.inviteData.firstName.$params.maxLength.max})}}</span>
                        </div>

                        <div class="form-group" :class="{ 'has-error': $v.inviteData.lastName.$error }">
                            <label for="lastName" class="control-label">{{$t("root.lastname")}} *</label>
                            <input class="form-control" type="text" name="lastName" id="lastName" placeholder=""
                                   :class="{'is-invalid' : $v.inviteData.lastName.$error}"
                                   v-model.trim="$v.inviteData.lastName.$model"/>
                            <span class="invalid-feedback" v-show="$v.inviteData.lastName.$error && !$v.inviteData.lastName.required">
                                {{$t("root.required")}}</span>
                            <span class="invalid-feedback" v-show="$v.inviteData.lastName.$error && !$v.inviteData.lastName.maxLength">
                                {{$t("root.generic_max_size", {max: $v.inviteData.lastName.$params.maxLength.max})}}</span>
                        </div>

                        <div class="form-group" :class="{ 'has-error': $v.inviteData.password.$error }">
                            <label for="password" class="control-label">{{$t("root.choose_password")}} *</label>
                            <input class="form-control" type="password" id="password"
                                   :class="{'is-invalid' : $v.inviteData.password.$error}"
                                   v-model="$v.inviteData.password.$model"/>
                            <span class="invalid-feedback" v-show="$v.inviteData.password.$error && !$v.inviteData.password.required">
                                {{$t("root.required")}}</span>
                            <span class="invalid-feedback" v-show="$v.inviteData.password.$error && !$v.inviteData.password.minLength">
                                {{$t("root.choose_password_size")}}</span>
                            <span class="invalid-feedback" v-show="$v.inviteData.password.$error && !$v.inviteData.password.maxLength">
                                {{$t("root.choose_password_max")}}</span>
                        </div>
                        <div class="form-group" :class="{ 'has-error': $v.inviteData.repeatPassword.$error }">
                            <label for="repeatPassword" class="control-label">{{$t("root.confirm_password")}} *</label>
                            <input class="form-control" type="password" id="repeatPassword"
                                   :class="{'is-invalid' : $v.inviteData.repeatPassword.$error}"
                                   v-model="$v.inviteData.repeatPassword.$model"/>
                            <span class="invalid-feedback" v-show="$v.inviteData.repeatPassword.$error && !$v.inviteData.repeatPassword.required">
                                {{$t("root.required")}}</span>
                            <span class="invalid-feedback" v-show="$v.inviteData.repeatPassword.$error && !$v.inviteData.repeatPassword.sameAsPassword">
                                {{$t("root.confirm_password_match")}}</span>
                        </div>

                        <div class="clearfix">
                            <v-button :loading="loading" type="submit" class="btn btn-primary zap-btn-rosa float-right"><i class="fa fa-floppy-o" aria-hidden="true"></i>&nbsp;
                                {{$t("root.create_account")}}</v-button>
                        </div>
                    </form>
                    <br/>
                    <br/>
                    <!--<p><small>By creating an account, you agree to our <a href="#">
                    Terms of Service and Privacy Policy</a></small></p>-->

                    <p><small>{{$t("root.already_have_account")}} <a :href="loginUrl">
                        {{$t("root.return_to_login")}}</a></small></p>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import {handleAjaxError} from "./utils";
    import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
    import vButton from './components/VButton';

    export default {
        name: "Join",
        components: {
            vButton,
        },
        // props: ["uuid"],
        data() {
            return {
                inviteData: {
                    email: null,
                    firstName: '',
                    lastName: '',
                    inviteId: '',
                    password: '',
                    repeatPassword: '',
                },

                // orgName: null,

                loading: false,
            }
        },
        validations: {
            inviteData: {
                firstName: {
                    required,
                    maxLength: maxLength(255)
                },
                lastName: {
                    required: required,
                    maxLength: maxLength(255)
                },
                password: {
                    required,
                    minLength: minLength(6),
                    maxLength: maxLength(255),
                },
                repeatPassword: {
                    required,
                    sameAsPassword: sameAs('password')
                },
            },
        },
        mounted() {
            // const self = this;
            // this.orgName = orgName;
            // eslint-disable-next-line
            this.inviteData.email = inviteEmail;
            // eslint-disable-next-line
            this.inviteData.inviteId = id;
        },
        computed: {
            loginUrl() {
                // eslint-disable-next-line
                return jsRoutes.controllers.Application.login().url;
            }
        },
        methods: {
            createAccount: function () {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // console.log("### INVALID");
                    return;
                }
                this.doCreateAccount();
            },

            doCreateAccount: function () {
                const self = this;
                // eslint-disable-next-line
                const url = jsRoutes.controllers.Application.createNewAccount().url;
                this.loading = true;

                axios.post(url, this.inviteData)
                    .then(function () {
                        // self.loading = false;
                        //console.log("roles -> " + JSON.stringify(data));
                        // eslint-disable-next-line
                        window.location.href = jsRoutes.controllers.Application.index().url;
                    })
                    .catch(function (response) {
                        self.loading = false;
                        // handle error
                        handleAjaxError(response);
                    });


            }

        }
    }
</script>

<style scoped>

</style>

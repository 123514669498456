export default {
    "error.general_title": "Erro",
    "error.general_failed_loading_data": "Falha no carregamento de dados",
    "error.general_failed_upload_file": "Falha no upload do '{file}' com o erro: {msg}",
    "error.general_cancel": "Cancelar",
    "error.general_delete": "Apagar",
    "success.general_title": "Sucesso",
    "success.image_caption_updated": "Legenda da imagem atualizada",
    "root.close": "Fechar",
    "root.organization": "Nome da Empresa",
    "root.required": "Este campo é obrigatório.",
    "root.onlynumbers": "Apenas números.",
    "root.organization_size": "Digite pelo menos 3 caracteres.",
    "root.organization_max": "O tamanho máximo é 255.",
    "root.firstname": "Primeiro nome",
    "root.lastname": "Último nome",
    "root.email": "Email",
    "root.email_valid": "O endereço de e-mail não é válido",
    "root.phone": "Telefone",
    "root.website": "Website",
    "root.vat": "NIF",
    "root.choose_password": "Escolha uma password",
    "root.choose_password_size": "Digite pelo menos 8 caracteres.",
    "root.choose_password_max": "O tamanho máximo é 255.",
    "root.password": "Password",
    "root.confirm_password": "Confirme a password",
    "root.confirm_password_match": "As passwords devem corresponder.",
    "root.password_changed": "Password changed.",
    "root.cancel": "Cancelar",
    "root.create_account": "Criar Conta",
    "root.something_weng_wrong": "Algo correu mal…",
    "root.invited_as_user": "Você foi convidado como usuário da organização",
    "root.already_have_account": "já tem uma conta?",
    "root.return_to_login": "Voltar ao login",
    "root.trial_period_ends": "O período de avaliação termina em",
    "root.last_subscription_period_end": "O último período da assinatura termina em",
    "root.subscription_ended": "Sua assinatura terminou em",
    "root.manage_your_subscriptions": "Faturação",
    "root.profile": "Perfil",
    "root.change_password": "Alterar Password",
    "root.profile_image": "Imagem de perfil",
    "root.upload_new_image": "Carregar nova imagem",
    "root.choose_file": "Escolher Ficheiro",
    "root.remove_image": "Remover imagem",
    "root.save": "Guardar",
    "root.profile_updated": "Perfil atualizado",
    "root.image_removed": "Imagem removida",
    "root.only_images_allowed": "Somente imagens são permitidas",
    "root.api_not_supported": "Desculpe, a API FileReader não é suportada",
    "root.file_uploaded": "Ficheiro carregado",
    "root.saved": "Guardado",
    "root.generic_max_size": "O tamanho máximo é {max}",
    "root.generic_min_size": "O tamanho mínimo é {min}",

    "widgets.imageCropModal.position_and_size": "Posicione e dimensione sua nova imagem",
    "widgets.imageCropModal.save_button": "Guardar",
    "widgets.imageCropModal.close_button": "Fechar",
    "UserInvite.title": "Convidar utilizadores",
    "UserInvite.fields.email": "Email",
    "UserInvite.fields.email_required": "Este campo é obrigatório.",
    "UserInvite.fields.email_invalid": "O endereço de e-mail não é válido.",
    "UserInvite.fields.user_type": "Tipo",
    "UserInvite.fields.user_type_required": "Este campo é obrigatório.",
    "UserInvite.button.send_invite": "Enviar convite",
    "UserInvite.button.go_back": "Voltar",
    "UserInvite.messages.failed_loading_roles": "Erro ao carregar funções",
    "UserInvite.messages.invite_sent": "Convite enviado",
    "UserList.title": "Utilizador",
    "UserList.invites_title": "Convites",
    "UserList.button.invite_user": "Convidar utilizador",
    "UserList.table.name": "Nome",
    "UserList.table.email": "Email",
    "UserList.table.type": "Tipo",
    "UserList.table.sent_by": "Enviado por",
    "UserList.table.date": "Data",
    "UserList.messages.delete_user": "Apagar utilizador",
    "UserList.messages.invites_table_empty": "Lista de convites vazia",
    "UserList.messages.delete_invite": "Apagar convite",
    "mainmenu.configuration": "Configuração",
    "mainmenu.users": "Utilizadores",
    "mainmenu.clients": "Clientes",
}

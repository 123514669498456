import Vue from "vue";
import Vuex from "vuex";

import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        showLoginModal: false,
        loginModalError: null,

        profile: {}
    },
    getters: {
        profile(state) {
            return state.profile;
        },
        isAdmin(state) {
            return state.profile.securityRoleName === 'super_admin';
        },
        showLoginModal(state) {
            return state.showLoginModal;
        },
        loginModalError(state) {
            return state.loginModalError;
        },
    },
    mutations: {
        'SET_PROFILE'(state, payload) {
            state.profile = payload;
        },
        'SHOW_LOGIN_MODAL'(state, show) {
            state.showLoginModal = show;
            state.loginModalError = null;
        },
        'SET_LOGIN_MODAL_ERROR'(state, msg) {
            state.loginModalError = msg;
        },
    },
    actions: {
        setProfile({commit}, payload) {
            commit('SET_PROFILE', payload);
        },
        loadProfileData({commit}) {
            // eslint-disable-next-line
            let url = jsRoutes.controllers.Account.loadUserProfile().url
            return axios.get(url).then(response => {
                commit('SET_PROFILE', response.data);
                return response.data;
            }, error => {
                throw error;
            })
        },
        setShowLoginModal({commit}, payload) {
            commit('SHOW_LOGIN_MODAL', payload);
        },
        setShowLoginModalExpired({commit}) {
            commit('SHOW_LOGIN_MODAL', true);
            commit('SET_LOGIN_MODAL_ERROR', 'Your session expired. Please login again.');
        },
    },
    modules: {}
});

<template>
  <div id="wrapper" :class="{'toggled' : showSidebar}">
    <sidebar></sidebar>
    <div id="content">
        <nav class="navbar navbar-light bg-light border-bottom navbar-expand zap-bg-rosa">
            <button type="button" @click="toggleSidebar" class="btn btn-default zap-text-white">
                <span class="sr-only">Toggle navigation</span>
                <span class="fa fa-bars fa-lg"></span>
            </button>

            <div class="collapse navbar-collapse zap-text-white">
                <div class="mr-auto"></div>
                <ul class="navbar-nav mt-2">
                    <!--<li class="nav-item">
                        <div id="languageChangerWidgetContainer"></div>
                    </li>
                    <li class="nav-item">
                        <div id="chatMessageWidgetContainer"></div>
                    </li>-->
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle zap-text-white" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{profile.name}}
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                            <router-link class="dropdown-item" :to="{name : 'UserProfile'}">Perfil</router-link>
                            <router-link class="dropdown-item" to="/change-password">{{$t("root.change_password")}}</router-link>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="/logout"><i class="fa fa-sign-out-alt"></i> Logout</a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
        <main role="main" class="container-fluid main-section">
            <keep-alive>
                <router-view :key="$route.fullPath"></router-view>
            </keep-alive>
        </main>
    </div>
      <login-modal :show="showLoginModal"></login-modal>
  </div>
</template>

<style lang="scss">
</style>
<script>
    import Sidebar from "./components/Sidebar";
    import {mapActions, mapGetters} from "vuex";
    import LoginModal from "./components/LoginModal";

    export default {
        components: {
            Sidebar,
            LoginModal
        },
        data () {
            return {
                showSidebar: true,
            }
        },
        mounted() {
            this.loadProfileData();
        },
        computed: {
            ...mapGetters({
                showLoginModal: 'showLoginModal',
                profile: 'profile'
            }),
        },
        methods: {
            ...mapActions(
                {
                    loadProfileData: 'loadProfileData',
                    setShowLoginModal: 'setShowLoginModal',
                    setShowLoginModalExpired: 'setShowLoginModalExpired',
                },
            ),
            toggleSidebar() {
                this.showSidebar = !this.showSidebar;
            }
        }
    }
</script>

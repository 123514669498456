import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/change-password",
        name: "ChangePassword",
        component: () =>
            import(/* webpackChunkName: "change-password" */ "../views/ChangePassword.vue")
    },
    {
        path: "/user-profile",
        name: "UserProfile",
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/UserProfile.vue")
    },
    {
        path: "/admin-users",
        name: "AdminUsers",
        meta: { section: 'users'},
        component: () =>
            import(/* webpackChunkName: "user-list" */ "../views/adminusers/AdminUserList.vue")
    },
    {
        path: "/admin-user-invite",
        name: "AdminUserInvite",
        meta: { section: 'users'},
        component: () =>
            import(/* webpackChunkName: "user-invite" */ "../views/adminusers/AdminUserInvite.vue")
    },
    {
        path: "/orgs",
        name: "OrganizationList",
        meta: { section: 'orgs'},
        component: () =>
            import(/* webpackChunkName: "orgs" */ "../views/organization/OrganizationList.vue")
    },
    {
        path: "/org/create",
        name: "OrganizationCreate",
        meta: { section: 'orgs'},
        component: () =>
            import(/* webpackChunkName: "orgs" */ "../views/organization/OrganizationCreate.vue")
    },
    {
        path: "/org/:id",
        name: "OrganizationDetail",
        meta: { section: 'orgs'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "orgs" */ "../views/organization/OrganizationDetail.vue")
    },
    {
        path: "/org/:id/users",
        name: "OrganizationUsers",
        meta: { section: 'orgs'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "orgs" */ "../views/organization/OrganizationUsers.vue")
    },
    {
        path: "/org/:id/products",
        name: "OrganizationProducts",
        meta: { section: 'orgs'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "orgs" */ "../views/organization/OrganizationProducts.vue")
    },
    {
        path: "/org/:id/networks-redes",
        name: "NetworksRedesList",
        meta: { section: 'orgs'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "networks" */ "../views/networks/NetworksRedesList.vue")
    },
    {
        path: "/org/:id/networks-oficinas",
        name: "NetworksOficinasList",
        meta: { section: 'orgs'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "networks" */ "../views/networks/NetworksOficinasList.vue")
    },
    {
        path: "/org/:orgid/networks-oficinas/criar",
        name: "NetworksOficinaCreate",
        meta: { section: 'orgs'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "networks" */ "../views/networks/NetworksOficinaCreate.vue")
    },
    {
        path: "/org/:orgid/networks-oficinas/:id/editar",
        name: "NetworksOficinaEditar",
        meta: { section: 'orgs'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "networks" */ "../views/networks/NetworksOficinaEdit.vue")
    },
    {
        path: "/org/:id/user-invite",
        name: "OrganizationUserInvite",
        meta: { section: 'orgs'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "orgs" */ "../views/organization/OrganizationUserInvite.vue")
    },
    {
        path: "/products",
        name: "ProductList",
        meta: { section: 'products'},
        component: () =>
            import(/* webpackChunkName: "products" */ "../views/product/ProductList.vue")
    },
    {
        path: "/product/:id",
        name: "ProductEdit",
        meta: { section: 'products'},
        props: true,
        component: () =>
            import(/* webpackChunkName: "products" */ "../views/product/ProductEdit.vue")
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue")
    }
];

const router = new VueRouter({
    routes
});

export default router;
